
.smsConv {
    border: solid black;
    border-width: 1px;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    max-width: 60%;
}

.smsConv.in {
    background-color: darkgreen;
    color: white;
}

.smsConv.out {
    background-color: #1485ee;
    color: white;
}