#sideFilter .nav-link a {
    color: #ffffff;
}

#sideFilter .nav-link:hover {
    background-color: #097ee8;
}

#sideFilter .active {
    color: #45cbf7;
}

#sideFilter .splitter {
    text-align: left;
    color: #505155;
    margin-bottom: -10px;
    margin-left: -5px;
    width: 100%;
}

#sideFilter .container {
    padding-left: 15px;
    padding-right: 0px;
    margin-right: auto;
    margin-left: auto;
    display: contents;
}

#sideFilter @keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

#sideFilter {
    border: none;
    z-index: 1000;
}



@media (max-width: 991px) {
    #sideFilter .navbar-nav .nav-item .nav-link {
        letter-spacing: 0.05rem;
        text-transform: uppercase;
        font-size:calc(15px);
        cursor: pointer;
        font-weight: 600;
        text-align: left;
    }

    #sideFilter .navbar-nav .nav-item {

    }

    #sideFilter {
        width: 18rem;
    }


    #sideFilter .navbar-collapse .navbar-nav {
        animation: 0.3s ease-in-out 0s 1 slideInFromLeft;
        padding-top: 50px;
        padding-left: 20px;
        position: fixed;
        top: 0;
        right: 0;
        display: flex;
        #flex-direction: column;
        width: 16rem;
        height: 100vh;
        background-color: #9ce7f7 !important;
    }
    #sideFilter .navbar-collapse. navbar-nav .nav-item {
        text-align: center;
    }
}

@media (min-width: 992px) {
    #sideFilter .navbar-nav .nav-item .nav-link {
        letter-spacing: 0.05rem;
        font-size:calc(15px);
        cursor: pointer;
        font-weight: bold;
    }
    #sideFilter .navbar-toggler:focus {
        outline-color: #d48a6e;
    }

    #sideFilter {
        padding-top: 50px;
        text-align: center;
        position: fixed;
        top: 0;
        right: 0;
        display: flex;
        z-index: 1004;
        #flex-direction: column;
        height: 100vh;
        background-color: #9ce7f7 !important;
    }
    #sideFilter .navbar-brand {
        display: flex;
        margin: auto auto 0;
        padding: 0.5rem;
    }
    #sideFilter .navbar-brand .img-profile {
        max-width: 10rem;
        max-height: 10rem;
        border: 0.5rem solid rgba(255, 255, 255, 0.2);
    }
    #sideFilter .navbar-collapse {
        display: flex;
        align-items: flex-start;
        flex-grow: 0;
        width: 100%;
        margin-bottom: auto;
    }
    #sideFilter .navbar-collapse .navbar-nav {
        flex-direction: column;
        width: 100%;

    }
    #sideFilter .navbar-collapse .navbar-nav .nav-item {
        display: block;
    }
    #sideFilter .navbar-collapse .navbar-nav .nav-item .nav-link {

    }
}