#sideNavHome .nav-link a {
    color: #ffffff;
}

#sideNavHome .nav-link:hover {
    background-color: #097ee8;
}

#sideNavHome .active {
    color: #45cbf7;
}

#sideNavHome .splitter {
    text-align: left;
    color: #505155;
    margin-bottom: -10px;
    margin-left: -5px;
    width: 100%;
}

#sideNavHome .container {
    padding-left: 15px;
    padding-right: 0px;
    margin-right: auto;
    margin-left: auto;
}

.MenuItemHome {
    font-size: 22px;
    padding: 4px;
}

.MenuItemHome:active {
    color: white;
}

.MenuItemHome .icon {
    padding-top: 1px;
    text-align: center;

    color: #505155;
    width: 30px;
    display: inline-block;
}

.MenuItemHome .rectangle {
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}

#sideNavHome @keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

#sideNavHome {
    border: none;
    z-index: 1000;
}



@media (max-width: 991px) {
    #sideNavHome .navbar-nav .nav-item .nav-link {
        letter-spacing: 0.05rem;
        text-transform: uppercase;
        font-size:calc(15px);
        cursor: pointer;
        font-weight: 600;
        text-align: left;
    }

    #sideNavHome .navbar-nav .nav-item {

    }

    #sideNavHome {
        width: 18rem;
    }


    #sideNavHome .navbar-collapse .navbar-nav {
        animation: 0.3s ease-in-out 0s 1 slideInFromLeft;
        padding-top: 50px;
        padding-left: 20px;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        #flex-direction: column;
        width: 16rem;
        height: 100vh;
        background-color: #202125 !important;
    }
    #sideNavHome .navbar-collapse. navbar-nav .nav-item {
        text-align: center;
    }
    .homeMain {
        background-color: #15161a;
        text-align: left;
        display: flex;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        padding-top: 7rem;
        padding-bottom: 1rem;
        position: relative;
        height: auto;
    }

    .homeMain .custom-switch {
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }

    .homeMain .mainContainer {
        background-color: #202125;
        text-align: left;
        min-height: 300px;
        border-radius: 10px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-bottom: 5px;
    }
}

.navbar-toggle {
    float: left !important;
}

@media (min-width: 992px) {
    #sideNavHome .navbar-nav .nav-item .nav-link {
        letter-spacing: 0.05rem;
        font-size:calc(15px);
        cursor: pointer;
        font-weight: bold;
    }
    #sideNavHome .navbar-toggler:focus {
        outline-color: #d48a6e;
    }

    #sideNavHome {
        padding-top: 50px;
        text-align: center;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        #flex-direction: column;
        height: 100vh;
        background-color: #202125 !important;
    }
    #sideNavHome .navbar-brand {
        display: flex;
        margin: auto auto 0;
        padding: 0.5rem;
    }
    #sideNavHome .navbar-brand .img-profile {
        max-width: 10rem;
        max-height: 10rem;
        border: 0.5rem solid rgba(255, 255, 255, 0.2);
    }
    #sideNavHome .navbar-collapse {
        display: flex;
        align-items: flex-start;
        flex-grow: 0;
        width: 100%;
        margin-bottom: auto;
    }
    #sideNavHome .navbar-collapse .navbar-nav {
        flex-direction: column;
        width: 100%;
    }
    #sideNavHome .navbar-collapse .navbar-nav .nav-item {
        display: block;
    }
    #sideNavHome .navbar-collapse .navbar-nav .nav-item .nav-link {

    }
    .homeMain {

        #align-items: center;

        padding-bottom: 5rem;
        position: relative;
        height: auto;
    }

    .homeMain .custom-switch {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .homeMain .mainContainer {
        background-color: #202125;
        text-align: left;
        min-height: 300px;
        border-radius: 10px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

.homeMain h4 {
    font-size: 26px !important;
}

.homeMain h5 {
    font-size: 20px !important;
}