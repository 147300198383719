

.DateInput {
    margin: 0;
    padding: 0;
    background: #ff000000;
    position: relative;
    display: inline-block;
    width: 130px;
    vertical-align: middle
}
.DateInput__small {
    width: 97px
}
.DateInput__block {
    width: 100%
}
.DateInput__disabled {
    background: #ff000000;
    opacity: 0.5;
    color: #dbdbdb
}
.DateInput_input {
    font-weight: 200;
    font-size: 19px;
    line-height: 24px;
    color: #484848;
    background-color: #ff000000;
    width: 100%;
    padding: 11px 11px 9px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0
}
.DateInput_input__small {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: .2px;
    padding: 7px 7px 5px
}
.DateInput_input__regular {
    font-weight: auto
}
.DateInput_input__readOnly {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}
.DateInput_input__focused {
    outline: 0;
    background: #ff000000;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid #008489;
    border-left: 0
}
.DateInput_input__disabled {
    background: #ff000000;
    font-style: italic
}
.DateInput_screenReaderMessage {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}
.DateInput_fang {
    position: absolute;
    width: 20px;
    height: 10px;
    left: 22px;
    z-index: 2
}
.DateInput_fangShape {
    fill: #fff
}
.DateInput_fangStroke {
    stroke: #dbdbdb;
    fill: transparent
}

.SingleDatePickerInput {
    display: inline-block;
    background-color: #ff000000
}
.SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
}
.SingleDatePickerInput__rtl {
    direction: rtl
}
.SingleDatePickerInput__disabled {
    background-color: #ff000000
}
.SingleDatePickerInput__block {
    display: block
}
.SingleDatePickerInput__showClearDate {
    padding-right: 30px
}
.SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%
}
.SingleDatePickerInput_clearDate__small {
    padding: 6px
}
.SingleDatePickerInput_clearDate__hide {
    visibility: hidden
}
.SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}
.SingleDatePickerInput_clearDate_svg__small {
    height: 9px
}
.SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}
.SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}
.SingleDatePicker {
    position: relative;
    display: inline-block
}
.SingleDatePicker__block {
    display: block
}
.SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}
.SingleDatePicker_picker__rtl {
    direction: rtl
}
.SingleDatePicker_picker__directionLeft {
    left: 0
}
.SingleDatePicker_picker__directionRight {
    right: 0
}
.SingleDatePicker_picker__portal {
    background-color: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}
.SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff
}
.SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
    color: darken(#cacccd,10%);
    text-decoration: none
}
.SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}