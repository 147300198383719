.highlight-clean {
  color:#313437;
  background-color:#fff;
  padding:50px 0;
}

.highlight-clean p {
  color:#7d8285;
}

.highlight-clean h2 {
  font-weight:bold;
  margin-bottom:25px;
  line-height:1.5;
  padding-top:0;
  margin-top:0;
  color:inherit;
}

.highlight-clean .intro {
  font-size:16px;
  max-width:500px;
  margin:0 auto 25px;
}

.highlight-clean .buttons {
  text-align:center;
}

.highlight-clean .buttons .btn {
  padding:16px 32px;
  margin:6px;
  border:none;
  background:none;
  box-shadow:none;
  text-shadow:none;
  opacity:0.9;
  text-transform:uppercase;
  font-weight:bold;
  font-size:13px;
  letter-spacing:0.4px;
  line-height:1;
  outline:none;
}

.highlight-clean .buttons .btn:hover {
  opacity:1;
}

.highlight-clean .buttons .btn:active {
  transform:translateY(1px);
}

.highlight-clean .buttons .btn-primary {
  background-color:#055ada;
  color:#fff;
}

.highlight-clean .buttons .btn-default {
  background-color:#e6e7ea;
  color:inherit;
}

