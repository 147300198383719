.main-appear {
    opacity: 0.01;
}

.main-appear.main-appear-active {
    opacity: 1;
    transition: opacity .5s ease-in;
}

.update-button {
    text-align: right;
}

.gpsLink {
    text-decoration: underline;
    color: #055ada;
    cursor: pointer;
}

.gpsLink:hover {
    filter: invert(60%);
}

.modal-90w {
    width: 90%;
}

.modal-60w {
    width: 50%;
}

.modal-60w-margin {

    margin-top: 50px
}

.update-button:hover {
    filter: invert(100);
    cursor: pointer;
}

.transparentButton2 {
    background: transparent;
    height: 100%;
    padding: 3px;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
}

.transparentButton2:hover{
    filter: invert(20%);
    cursor: pointer;
}

.transparentButton {
    background: transparent;
    height: 100%;
    padding: 5px;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
}

.transparentButton:disabled {
    opacity: 0.5;
}

.transparentButton.left {
    text-align: left;
}

.transparentButton:hover{
    filter: invert(60%);
    cursor: pointer;
}

.ContainerListItem {
    background-color:#ffffff;
    padding:8px;
    padding-left:15px;
    padding-right:15px;
    border-radius:4px;
    color:#505e6c;
    box-shadow:3px 3px 10px rgba(0,0,0,0.5);
    margin-bottom: 8px;
    margin-right: 20px;
    margin-left: 20px;
}

.vertical-text {
    transform: rotate(-90deg);
    display: inline-block;
    float: left;
    margin-left: -15px;
}

.dataTable .tr {
    margin-bottom: 1px;
}

.dataTable td {
    white-space: nowrap;
    padding: 2px 2px 2px 3px;
    border: 1px solid #ddd;
}

.dataTable th {
    white-space: nowrap;
    padding: 8px;
    border: 1px solid #ddd;
}

.dataTable .red td {
    background-color: deepskyblue;
}

.dataTable .normal td {
}

.dataTable td :focus {
    border: 3px solid red;
}

.dataTable tr:nth-child(even){
    background-color: #f2f2f2;
}

.dataTable th {
    border: 1px solid #ddd;
    border-bottom: none;
    font-weight: bold;
    font-size: 14px;
    background-color: #222222;
    color: white;
}

.dataTable .even {
    background-color: #696969;
}

.dataTable .transparent {
    background-color: white;
    border: transparent;
}

.dispatchingTable .tr {
    margin-bottom: 1px;
}

.dispatchingTable td {
    white-space: nowrap;
    padding: 2px 2px 2px 3px;
    border: 1px solid #ddd;
}

.dispatchingTable th {
    white-space: nowrap;
    padding: 3px 8px;
    border: 1px solid #ddd;
}

.dispatchingTable .red td {
    background-color: deepskyblue;
}

.dispatchingTable .normal td {
}

.dispatchingTable td :focus {
    border: 3px solid red;
}

.dispatchingTable tr:nth-child(even){
    background-color: #f2f2f2;
}

.dispatchingTable th {
    border: 1px solid #ddd;
    border-bottom: none;
    font-weight: bold;
    font-size: 13px;
    background-color: #222222;
    color: grey;
}

.dispatchingTable .even {
    background-color: #696969;
}

.dispatchingTable .transparent {
    background-color: white;
    border: transparent;
}

.historyContainer {
    overflow-y: scroll;
    max-height: 250px;
}

.history {
    background-color: white;
    padding: 5px;
}

.history.even {
    background-color: #eeeeee;
}

.transparent {
    background-color: transparent;
    border: transparent;
}

.fixedTopMenu{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.IconWithNumberBase {
    display: inline-block;
    vertical-align: middle;
    max-width: 60px;
    position: relative;
}

.IconWithNumberBase .warning{
    color: #f95d00;
    position: absolute;
    text-align: center;
    font-size: 14px;
    padding-left: 6px;
    min-width: 10px;
    z-index: 100;
    display: inline-block;
}

.IconWithNumberBase .RedCircle{
    background-color: red;
    color: white;
    position: absolute;
    text-align: center;
    font-size: 10px;
    border-radius: 50%;
    padding-left: 6px;
    padding-right: 6px;
    min-width: 15px;
    z-index: 100;
    display: inline-block;
}

.InvoiceViewerTr {
    cursor: pointer;
}

.InvoiceViewerTr:hover {
    background-color: #c5ebf0;
}

.InvoiceViewerTr td{
    max-width: 200px;
    word-wrap: break-word;
    white-space: normal;
    padding: 5px;
}

.InvoiceViewerTr th{
    max-width: 200px;
    word-wrap: break-word;
    white-space: normal;
    padding: 5px;
}

.InvoiceViewerTr:nth-child(even):hover {
    background-color: #c5ebf0;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    background: transparent;
    border-color: transparent;
    border: transparent;
    width: 100%;
    height: 100%;
    padding: 5px;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    display: inline-block;
    text-align: left;
}

.inputfile:focus + label {
    outline: 1px dotted #055ada;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label:hover {
    color: #daab70;
}

/*.ContainerListItem:hover{
    background-color: #2e6da4;
    cursor: pointer;
    color: white;
    -webkit-animation-name: example;
    -webkit-animation-duration: 0.5s;
    animation-name: example;
    animation-duration: 0.5s;
}*/

.sidePanelPopover {
    max-height: 300px;
    overflow: auto;
}

.dropdown-menu {
    max-height: 500px;
    overflow-y: auto;
}

.fixed-top {
    position: fixed;
    letf: 0;
    top: 51px;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 1003;
    width: 100%;

    background-color: #a1e9fa;
}

.filterStyle {

}

.popover {
    max-width: none;
}

.circle {
    margin-right: 5px;
    margin-left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.legendFont {
    font-size: 12px;
}

.buttonMarkerWrapper {
    display: flex;
    border: none;
}

.buttonMarker {
    cursor:pointer;
    width: 8px;
    height: 30px;
    margin-left: 1px;
    margin-right: 1px;
}

.buttonMarker:hover {
    transform: scale(1.1);
}

.buttonMarkerLine {
    width: 2px;
    height: 30px;
    background: white;
    -webkit-transform:
            translateX(3px);
            /*rotate(12deg);*/
    position: absolute;
}

/* Standard syntax */
@keyframes example {
    from {background-color: transparent; color: black}
    to {background-color: #2e6da4; color: white}
}

.webdispecink{
    background: url('../webdispecink1.ico') 0 0 !important;
    width: 32px;
    height: 32px;
    display: inline-block;
}

.unifiedfleet{
    background: url('../unifiedfleet.png') 0 0 !important;
    width: 32px;
    height: 32px;
    display: inline-block;
}

.volvo{
    background: url('../volvo.png') 0 0 !important;
    width: 32px;
    height: 32px;
    display: inline-block;
}

.uta-logo{
    background: url('../uta-logo.png') 0 0 !important;
    width: 32px;
    height: 32px;
    display: inline-block;
}

.shell-logo{
    background: url('../shell-logo.png') 0 0 !important;
    width: 32px;
    height: 32px;
    display: inline-block;
}

.wag-logo{
    background: url('../wag-logo.png') 0 0 !important;
    width: 32px;
    height: 32px;
    display: inline-block;
}

.uta-logo16{
    background: url('../uta-logo.png') 0 0 !important;
    width: 32px;
    height: 32px;
    transform: scale(0.5, 0.5);
    -ms-transform: scale(0.5, 0.5);
    -webkit-transform: scale(0.5, 0.5);
    display: inline-block;
}

.shell-logo16{
    background: url('../shell-logo.png') 0 0 !important;
    width: 32px;
    height: 32px;
    transform: scale(0.5, 0.5);
    -ms-transform: scale(0.5, 0.5);
    -webkit-transform: scale(0.5, 0.5);
    display: inline-block;
}

.wag-logo16{
    background: url('../wag-logo.png') 0 0 !important;
    width: 32px;
    height: 32px;
    transform: scale(0.5, 0.5);
    -ms-transform: scale(0.5, 0.5);
    -webkit-transform: scale(0.5, 0.5);
    display: inline-block;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 100px;
    border-radius: 2px;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid #eee;
    padding: 0 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    cursor: move;
    border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 200px;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.autocomplete input {
    display: block;
    width: 100%;
    min-width: 100px;
    height: 34px;
    padding: 6px 12px;
    font-size: 11px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.minWidth input {
    min-width: 250px;
}

.linkStyle {
    color: #055ab4;
    text-decoration: underline;
    cursor: pointer;
}

.footerText {
    color: #aaa;
    border-top: #aaa solid 1px;
    padding: 30px;
    margin-bottom: 20px;
    margin-top: 40px;
}

.fixHowirizonalScroll {
    padding-right: -30px;
    padding-left: -30px;
    margin-right: 15px;
    margin-left: 15px;
}

.previewBox {
    padding: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
    border: #d4d4d4 solid 1px;
}

.issuedOrderListItem {
    border-bottom: #1485ee solid thin;
    margin-bottom: 10px;
    padding-bottom: 5px;
}

.edditableTagTextArea {
    border: none;
    outline: none;
    padding: 5px;
    resize: none;
    background-color: transparent;
    font-size: 12px;
    height: auto;
    width: 92%;
    max-width: 5000px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.filterContainer {
    margin-left: 10px;
    margin-right: 10px;
    padding-right: 3px;
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: #172a74 solid 2px;
    border-radius: 5px;
    background-color: azure;
}