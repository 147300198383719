
.RecordAttachment {

    cursor: pointer;
    transition: all .2s ease-in-out;
}

.RecordAttachment:hover {
    transform: scale(1.1);
}

.Record {
    padding:20px 0;
}

.Record .nest {
    width:90%;
    margin:0 auto;
    background-color:#ffffff;
    padding: 10px 10px 10px 30px;
    border-radius:4px;
    color:#505e6c;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow:3px 3px 5px rgba(0,0,0,0.1);
}

.Record .illustration {
    text-align:center;
    padding:0 0 20px;
    font-size:70px;
    color:#055ada;
}

.Record .form-control {
    background: #ffffff;
    border:none;
    border:1px solid #dfe7f1;
    border-radius:0;
    box-shadow:none;
    font-size: 12px;
    outline:none;
    color:inherit;
    text-indent:8px;
    height:42px;
}

.Record .form-control:disabled {
    background-color: #f4f6f9;
}

.Record .textarea {
    border:1px solid #dfe7f1;
    border-radius:10px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow:none;
    outline:none;
    width: 100%;
    color:inherit;
    height:200px;
}

.Record .control-label {

}

.Record .form-group {
    padding-top: 5px;
}

.btn.btn-primary.btn-block {
}

.Record .btn-primary {
    background:#055ada;
    border:none;
    border-radius:4px;
    padding:11px;
    box-shadow:none;
    margin-top:26px;
    text-shadow:none;
    outline:none !important;
}
