.editForm {
  padding:20px 0;
}

.editForm .nest {
  width:98%;
  margin:0 auto;
  background-color:#ffffff;
  padding:20px;
  border-radius:4px;
  color:#505e6c;
  box-shadow:3px 3px 5px rgba(0,0,0,0.1);
}

.editForm .illustration {
  text-align:center;
  padding:0 0 20px;
  font-size:70px;
  color:#055ada;
}

.editForm .form-control {
  background: #ffffff;
  border:none;
  border:1px solid #dfe7f1;
  border-radius:0;
  box-shadow:none;
  outline:none;
  color:inherit;
  text-indent:8px;
  height:42px;
}

.editForm .form-control:disabled {
  background-color: #f4f6f9;
}

.editForm .textarea {
  background: #f4f6f9;
  border:1px solid #dfe7f1;
  border-radius:10px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow:none;
  outline:none;
  width: 100%;
  color:inherit;
  height:200px;
}

.editForm .control-label {

}

.editForm .form-group {
  padding-top: 5px;
}

.btn.btn-primary.btn-block {
}

.editForm .btn-primary {
  background:#055ada;
  border:none;
  border-radius:4px;
  padding:11px;
  box-shadow:none;
  margin-top:26px;
  text-shadow:none;
  outline:none !important;
}

.editFormMarginButtonTop .btn-primary {
  margin-top:0px;
}

.editForm .btn-primary:hover, .editForm .btn-primary:active {
  background:#055ab4;
}

.editForm .btn-primary:active {
  transform:translateY(1px);
}

.editForm .forgot {
  display:block;
  text-align:center;
  font-size:12px;
  color:#6f7a85;
  opacity:0.9;
  text-decoration:none;
}

.editForm .forgot:hover, .editForm .forgot:active {
  opacity:1;
  text-decoration:none;
}

